//Width
$tablet-min-width: 576px;
$desktop-min-width: 1024px;

//Colors
$main-yellow-text-color: #ffe500;
$sub-yellow-text-color: #f7e439;
$white-text-color: #ffffff;
$dark-text-color: #000000;
$pink-text-color: #9364ed;
$violet-text-color: #5e1dbb;
$gray-color: #191919;
$lightgray-text-color: #ececec;
$lightgray-color: #808080;
$stack-gray-color: #282828;
$pink2-text-color: #e2209e;
$blue-text-color: #07cbf5;

//Fonts
$fira-code: "Fira Code";
$sugo-pro-classic: "Sugo Pro Classic";
$sugo-pro-display: "Sugo Pro Display";

// Width of dispaly
$phone-min-width: 320px;
$big-phone-min-width: 480px;
$tablet-min-width: 600px;
$tablet2-min-width: 765px;
$small-desktop-min-width: 1023px;
$desktop-min-width: 1200px;
$big-desktop-min-width: 1350px;
$big-big-desktop-min-width: 1500px;
