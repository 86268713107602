//Fira-Code
@font-face {
  font-family: "Fira Code";
  src: url("../fonts/Fira-Code/FiraCode-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Fira Code";
  src: url("../fonts/Fira-Code/FiraCode-Medium.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Fira Code";
  src: url("../fonts/Fira-Code/FiraCode-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Fira Code";
  src: url("../fonts/Fira-Code/FiraCode-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Fira Code";
  src: url("../fonts/Fira-Code/FiraCode-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

//Sugo-Pro-Classic
@font-face {
  font-family: "Sugo Pro Classic";
  src: url("../fonts/Sugo-Pro-Classic/Sugo-Pro-Classic-Thin-Italic-trial.ttf")
    format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Sugo Pro Classic";
  src: url("../fonts/Sugo-Pro-Classic/Sugo-Pro-Classic-Thin-trial.ttf")
    format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Sugo Pro Classic";
  src: url("../fonts/Sugo-Pro-Classic/Sugo-Pro-Classic-ExtraLight-Italic-trial.ttf")
    format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Sugo Pro Classic";
  src: url("../fonts/Sugo-Pro-Classic/Sugo-Pro-Classic-ExtraLight-trial.ttf")
    format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Sugo Pro Classic";
  src: url("../fonts/Sugo-Pro-Classic/Sugo-Pro-Classic-Light-Italic-trial.ttf")
    format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Sugo Pro Classic";
  src: url("../fonts/Sugo-Pro-Classic/Sugo-Pro-Classic-Light-trial.ttf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Sugo Pro Classic";
  src: url("../fonts/Sugo-Pro-Classic/Sugo-Pro-Classic-Regular-trial.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Sugo Pro Classic";
  src: url("../fonts/Sugo-Pro-Classic/Sugo-Pro-Classic-Bold-Italic-trial.ttf")
    format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Sugo Pro Classic";
  src: url("../fonts/Sugo-Pro-Classic/Sugo-Pro-Classic-Bold-trial.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
}

//Sugo-Pro-Display
@font-face {
  font-family: "Sugo Pro Display";
  src: url("../fonts/Sugo-Pro-Display/Sugo-Pro-Display-Thin-Italic-trial.ttf")
    format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Sugo Pro Display";
  src: url("../fonts/Sugo-Pro-Display/Sugo-Pro-Display-Thin-trial.ttf")
    format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Sugo Pro Display";
  src: url("../fonts/Sugo-Pro-Display/Sugo-Pro-Display-ExtraLight-Italic-trial.ttf")
    format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Sugo Pro Display";
  src: url("../fonts/Sugo-Pro-Display/Sugo-Pro-Display-ExtraLight-trial.ttf")
    format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Sugo Pro Display";
  src: url("../fonts/Sugo-Pro-Display/Sugo-Pro-Display-Light-Italic-trial.ttf")
    format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Sugo Pro Display";
  src: url("../fonts/Sugo-Pro-Display/Sugo-Pro-Display-Light-trial.ttf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Sugo Pro Display";
  src: url("../fonts/Sugo-Pro-Display/Sugo-Pro-Display-Regular-trial.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Sugo Pro Display";
  src: url("../fonts/Sugo-Pro-Display/Sugo-Pro-Display-Bold-Italic-trial.ttf")
    format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Sugo Pro Display";
  src: url("../fonts/Sugo-Pro-Display/Sugo-Pro-Display-Bold-trial.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
}
