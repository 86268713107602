@import "../../styles/utils/vars";
@import "../../styles/utils/mixins";

.page-title {
  margin: 0;
  position: relative;
  display: inline-block;

  font-family: $sugo-pro-display;
  color: $white-text-color;
  font-size: 76px;
  font-style: normal;
  font-weight: 400;
  line-height: 80px; /* 100% */
  letter-spacing: 3.04px;
  text-transform: uppercase;

  text-shadow:
    2.5px 2.5px 0px #000000,
    3.5px 3.5px 0px #4d4d4d;

  @include onPhone {
    font-size: 48px;
    line-height: 52px; /* 100% */
    letter-spacing: 1.92px;
    text-align: center;
  }

  @include onTablet {
    font-size: 76px;
    line-height: 80px; /* 101.818% */
    letter-spacing: 3.04px;
    text-align: start;
  }

  &--yellow {
    color: $main-yellow-text-color;
  }

  &--blue {
    color: $blue-text-color;
  }

  &--pink {
    color: $pink2-text-color;
  }
}

.home-top {
  z-index: 1;

  &__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include onTablet {
      flex-direction: row;
      justify-content: space-between;
      column-gap: 1rem;
    }
  }

  &__text-block {
    position: relative;
    z-index: 2;

    margin-top: 110px;

    @include onPhone {
      max-width: 480px;
    }

    @include onTablet {
      max-width: 753px;
    }
  }

  &__page-title {
    display: block;

    &--yellow {
      color: $main-yellow-text-color;
    }
  }

  &__block-under-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include onPhone {
      max-width: 480px;
    }

    @include onTablet {
      max-width: 570px;
    }
  }

  &__sub-text {
    display: block;
    padding: 0;
    margin-bottom: 32px;
    width: 100%;
    font-family: $fira-code;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    color: $white-text-color;

    @include onPhone {
      font-size: 15px;
      line-height: 22px; /* 100% */
      letter-spacing: 0.6px;
      text-align: center;
      margin-top: 20px;
    }

    @include onTablet {
      font-size: 20px;
      line-height: 30px; /* 173.333% */
      letter-spacing: 1.2px;
      text-align: start;
      margin-top: 16px;
    }
  }

  &__field {
    width: 100%;
    margin-bottom: 24px;
    position: relative;
  }

  &__select {
    border: 2px solid #3d3d3d;
    border-radius: 0;
    background-color: transparent;
    outline: none;
    // width: calc(100% - 26px);
    width: 100%;
    box-sizing: border-box;

    padding-left: 8px;
    padding-right: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #3d3d3d;
    font-family: inherit;
    font-style: normal;
    font-weight: 500;

    @include onPhone {
      height: 52px;
      font-size: 13px;
      line-height: 30px;
      letter-spacing: 0.52px;
    }

    @include onTablet {
      height: 72px;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 1.2px;
    }

    &--danger {
      border-color: #e2209e;
    }

    &:focus {
      color: white;
      border-color: var(--Blue, #07cbf5);
    }

    &-value {
      opacity: 0.7;
      transition:
        opacity 0.3s,
        color 0.3s;
      color: #fff; // Set the initial color to white
      margin-left: 12px;

      &--selected {
        opacity: 1;
      }
    }

    &:focus &-value {
      opacity: 1;
    }
  }

  // Style for the selected value and focus state
  &:focus &-value,
  &-value:not(:empty) {
    opacity: 1;
    color: white;
  }

  &__arrow {
    width: 24px;
    height: 24px;
  }

  &__options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #000;
    border-top: none;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 10;

    li {
      color: white;
      cursor: pointer;

      @include onPhone {
        padding: 4px;
        font-size: 13px;
        letter-spacing: 0.52px;
        line-height: 30px;
      }

      @include onTablet {
        padding: 14px;
        font-size: 20px;
        letter-spacing: 1.2px;
      }

      &:hover {
        background-color: #525252;
      }
    }
  }

  &__input {
    border: 2px solid #3d3d3d; /* Added border */
    border-radius: 0; /* Squared corners */
    background-color: transparent;
    outline: none;
    border: 2px solid #3d3d3d;
    border-radius: 0;
    background-color: transparent;
    outline: none;
    // width: calc(100% - 22px); /* Adjust width to accommodate padding */
    width: 100%;
    box-sizing: border-box;

    color: inherit;
    font-family: inherit;
    text-transform: none;
    font-weight: 500;
    font-style: normal;
    color: #3d3d3d; /* Initial color for placeholder text */
    padding-left: 20px;

    &::placeholder {
      color: #fff; /* Brighter placeholder text */
      opacity: 0.7;
      transition: opacity 0.3s;
    }

    &:focus::placeholder {
      opacity: 0; /* Hide placeholder on focus */
    }

    &--danger {
      border-color: #e2209e;
    }

    &:focus {
      color: white;
      border-color: var(--Blue, #07cbf5);
    }

    &--filled {
      color: white; /* Color when input is filled */
    }

    @include onPhone {
      height: 52px;
      font-size: 13px;

      line-height: 30px;
      letter-spacing: 0.52px;
    }

    @include onTablet {
      height: 72px;
      font-size: 20px;
      letter-spacing: 1.2px;
    }
  }

  &__input:not(:placeholder-shown) {
    color: white; /* Change color when input is filled */
  }

  & __input:focus {
    color: white; /* Ensure color remains white on focus */
    border-color: #e2209e; /* Optional: change border color on focus */
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    background: url("../../images/button.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border: none;

    font-family: $fira-code;
    color: #000;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      color: $main-yellow-text-color;
      background: url("../../images/button_hover.svg");
      background-size: 100% 100%;
    }

    @include onPhone {
      height: 60px;
      font-size: 17px;
      letter-spacing: 0.34px;
    }

    @include onTablet {
      height: 82.442px;
      font-size: 22px;
      letter-spacing: 0.44px;
    }
  }

  &__button-2 {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    background: url("../../images/button_hover.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border: none;

    font-family: $fira-code;
    color: $main-yellow-text-color;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 16px;

    &:hover {
      color: #000;
      background: url("../../images/button.svg");
      background-size: 100% 100%;
    }

    @include onPhone {
      height: 60px;
      font-size: 17px;
      letter-spacing: 0.34px;
    }

    @include onTablet {
      height: 82.442px;
      font-size: 22px;
      letter-spacing: 0.44px;
    }
  }

  &__illustration {
    &--phone {
      display: none;

      @include onPhone {
        display: flex;
        width: 100%; // Changed from fixed 300px to 100% of container
        max-width: 300px; // Added max-width to limit size
        height: auto; // Changed from fixed height to auto
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        img {
          // Added img selector to target the image directly
          max-width: 100%;
          max-height: 361px; // Adjust this value as needed
          object-fit: contain; // Ensures the image keeps its aspect ratio
        }
      }

      @include onTablet {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include onTablet {
        display: inline-flex;
        width: 737px;
        height: 888px;
        margin-top: 0;
        text-align: start;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        z-index: 1;
      }
    }
  }

  &__shadow {
    position: absolute;
    bottom: 0;
    width: 120%;
    height: 350px;

    @include onSmallDesktop {
      bottom: -35%;
    }

    @include onBigDesktop {
      bottom: -9%;
    }
  }

  &__our-client {
    block-size: border-box;
    width: 100%;

    @include onSmallDesktop {
      padding-top: 100px;
    }

    @include onDesktop {
      padding-top: 0;
    }

    &__content {
      position: relative;
      margin-top: -30px;
      z-index: 1;

      padding: 50px 20px;
      height: max-content;
      background-image: url("../../images/project_info_mobile_img.svg");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      @include onTablet {
        position: relative;
        padding: 25px 100px 45px;
      }

      @include onSmallDesktop {
        background-image: url("../../images/project_info_img.svg");
        padding: 2.5rem 4.5rem;
        margin-top: 10%;
      }

      @include onBigDesktop {
        padding-top: 32px;
        padding-bottom: 64px;
        margin-top: 0%;
      }
    }

    &__block-img {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 1.5rem;
      row-gap: 2rem;
      align-items: center;

      @include onTablet {
        justify-content: center;
        column-gap: 50px;
        align-items: center;
      }

      @include onSmallDesktop {
        justify-content: center;
        column-gap: 90px;
        row-gap: 40px;
      }

      @include onBigDesktop {
        column-gap: 100px;
      }
    }

    &__img {
      height: 30px;

      @include onBigTablet {
        height: 30px;
      }

      @include onSmallDesktop {
        height: 40px;
      }
    }

    &__left-tape {
      z-index: 10;
      position: absolute;
      width: 85px;
      top: -10px;
      left: -20px;

      @include onTablet {
        width: 100px;
        display: block;
        position: absolute;
        top: -1.5rem;
        left: -1.5rem;
      }

      @include onSmallDesktop {
        width: 120px;
      }
    }

    &__right-tape {
      z-index: 100;
      position: absolute;
      width: 85px;
      top: -10px;
      right: -20px;

      @include onTablet {
        width: 100px;
        display: block;
        // z-index: 2;
        position: absolute;
        top: -1.5rem;
        right: -1.5rem;
      }

      @include onSmallDesktop {
        width: 120px;
      }
    }
  }
}

.gradient {
  width: 1488px;
  position: relative;
  // left: 50%;
  // right: 50%;
  margin-top: -1200px;
  margin-left: 600px;
  margin-right: -50vw;
  background: url("../../images/ellipse_1.svg") center no-repeat;
  background-size: cover;
  height: 1488px;
  z-index: 0;
  opacity: 0.6;
}

.home-money {
  &__content {
    box-sizing: border-box;
    padding-top: 100px;
    position: relative;
    overflow: hidden;
    z-index: -100px;

    @include onBigTablet {
      padding-top: 230px;
    }

    @include onDesktop {
      overflow: visible;
      padding-top: 300px;
    }
  }

  &__banan-bg {
    box-sizing: border-box;

    // max-width: 48rem;
    max-width: 480px;
    width: 100%;

    z-index: 1;
    position: absolute;
    top: 33%;
    right: 0;

    @include onTablet {
      // max-width: 25rem;
      // right: -10rem;
      // top: 25rem;
      right: 0;
    }

    @include onBigTablet {
      max-width: 370px;
      top: 31%;
    }

    @include onSmallDesktop {
      max-width: 500px;
      right: 0;
      top: 31%;
    }

    @include onDesktop {
      max-width: 630px;
    }

    @include onBigDesktop {
      max-width: 860px;
      top: 30%;
      right: -12%;
    }
  }

  &__dev-bg {
    max-width: 480px;
    width: 100%;

    position: absolute;
    left: 0;
    bottom: 17%;

    @include onTablet {
      // max-width: 25rem;
      // bottom: -20rem;
      // left: 0;
      // height: 100%;
      // left: -12rem;
    }

    @include onBigTablet {
      max-width: 370px;
      bottom: 15%;
    }

    @include onSmallDesktop {
      max-width: 480px;
      bottom: 11%;
      left: 0;
      // bottom: -25%;
    }

    @include onDesktop {
      max-width: 550px;
    }

    @include onBigDesktop {
      max-width: 750px;
      left: -12%;
      bottom: 4%;
    }
  }

  &__page-title {
    &--position {
      margin-bottom: 70px;

      @include onBigTablet {
        margin-bottom: 100px;
      }
    }
  }

  &__title {
    color: $main-yellow-text-color;
    font-family: $sugo-pro-display;
    font-size: 52px;
    font-style: normal;
    font-weight: 400;
    line-height: 54px; /* 103.846% */
    letter-spacing: 3.12px;
    text-transform: uppercase;
    margin-bottom: 70px;

    @include onTablet {
      margin-bottom: 6rem;
    }

    @include onSmallDesktop {
      padding-left: 2rem;
    }
  }

  &__img {
    box-sizing: border-box;
    max-width: 25rem;
    width: 100%;
    margin-bottom: 20px;

    @include onSmallDesktop {
      width: 380px;
    }

    @include onBigDesktop {
      width: 450px;
    }

    &--2 {
      height: 27rem;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    row-gap: 10rem;
    position: relative;
    z-index: 2;

    @include onBigTablet {
      display: grid;
      grid-template-columns: (2, 1fr);
      grid-template-rows: (3, 1fr);
      column-gap: 3rem;
    }

    @include onSmallDesktop {
      column-gap: 20px;
    }
  }

  &__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    @include onBigTablet {
      padding: 0;
    }

    &--1 {
      @include onBigTablet {
        grid-column: 1;
        grid-row: 1;
        padding-right: 6rem;

        justify-self: flex-end;
        text-align: left;
      }
    }

    &--2 {
      text-align: right;

      @include onBigTablet {
        grid-column: 2;
        grid-row: 2;
        justify-self: center;

        margin-top: -20rem;
        text-align: right;
      }

      @include onSmallDesktop {
        margin-top: -13rem;
      }
    }

    &--3 {
      @include onBigTablet {
        grid-column: 1;
        grid-row: 3;
        justify-self: center;

        margin-top: -13rem;
        text-align: right;
      }

      @include onSmallDesktop {
        text-align: left;
        margin-top: -13rem;
      }
    }
  }

  &__step-info {
    margin: 0;

    display: block;
    max-width: 25rem;
    width: 100%;

    color: $white-text-color;
    font-family: $fira-code;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 173.333% */
    letter-spacing: 0.6px;

    @include onBigTablet {
      font-size: 13px;
      line-height: 22px; /* 169.231% */
      letter-spacing: 0.52px;
    }

    @include onSmallDesktop {
      font-size: 18px;
      line-height: 28px; /* 155.556% */
      letter-spacing: 0.72px;
    }

    @include onBigDesktop {
      font-size: 20px;
      line-height: 30px; /* 150% */
      letter-spacing: 1.2px;
    }

    &--2 {
      // @include onTablet {
      //   align-self: flex-end;
      // }
    }
  }

  &__button {
    width: 100%;
    height: 60px;
    background: url("../../images/button.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    border: none;

    font-family: $fira-code;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;

    cursor: pointer;

    &:hover {
      color: $white-text-color;
      // padding: 1.5rem 4.5rem;

      background: url("../../images/button_hover.svg");
      background-size: 100% 100%;
    }

    @include onBigTablet {
      width: 246px;
      height: 60px;

      font-size: 15px;
      letter-spacing: 0.3px;
    }

    @include onSmallDesktop {
      width: 302px;
      height: 74px;

      font-size: 18px;
      letter-spacing: 0.36px;
    }

    @include onBigDesktop {
      width: 365px;
      height: 82px;

      font-size: 22px;
      letter-spacing: 0.44px;
    }

    &--position {
      display: block;
      margin-top: -5rem;

      @include onBigTablet {
        display: block;

        position: absolute;
        bottom: 35px;
        right: 0;
      }
    }
  }
}

.home-offer {
  &__content {
    @include onPhone {
      padding-top: 108px;
    }

    @include onTablet {
      padding-top: 246px;
    }
  }

  &__horizontal-line {
    display: block; /* Show lines on larger screens */
    stroke-width: 1px;
    stroke: #fff;

    @include onTablet {
      /* Tablet and above */
      display: none; /* Hide lines on smaller screens */
    }
  }

  &__title {
    text-align: center;
    width: 100%;

    @include onPhone {
      font-size: 48px;
      line-height: 52px;
      letter-spacing: 1.92px;
      margin-bottom: 51px;
    }

    @include onTablet {
      font-size: 68px;
      line-height: normal;
      letter-spacing: 4.08px;
      margin-bottom: 110px;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5rem;

    @include onBigTablet {
      flex-direction: row;
      column-gap: 2rem;
      justify-content: space-between;
      align-items: start;
    }

    @include onSmallDesktop {
      // column-gap: 10rem;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-inline: 2rem 2rem;

    @include onPhone {
      align-items: center;
      text-align: center;
    }

    @include onTablet {
      padding-inline: 0 0;
      align-items: flex-start;
      text-align: start;
    }
    &--1 {
      grid-column: 1;
      justify-self: center;
    }

    &--2 {
      grid-column: 2;
    }

    &--3 {
      grid-column: 3;
      padding-bottom: 0.35rem;
    }
  }

  &__img {
    width: 160px;
    height: 160px;
    margin-bottom: 52px;
  }

  &__card-title {
    margin: 0;
    margin-bottom: 12px;

    color: $main-yellow-text-color;
    font-family: $fira-code;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.44px;
  }

  &__card-info {
    max-width: 380px;
    margin: 0;

    color: $white-text-color;
    font-family: $fira-code;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
    letter-spacing: 1.08px;
  }

  &__proposal {
    display: flex;
    align-items: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 1.2rem;
  }

  &__list-img {
    width: 23px;

    margin-right: 12px;

    @include onBigTablet {
      width: 17px;
      margin-right: 10px;
    }

    @include onBigTablet {
      width: 23px;

      margin-right: 12px;
    }

    @include onBigDesktop {
      width: 25px;

      margin-right: 16px;
    }
  }

  &__list-text {
    color: $white-text-color;
    font-family: $fira-code;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 200% */
    letter-spacing: 0.6px;

    @include onBigTablet {
      font-size: 11px;
      letter-spacing: 0.44px;
    }

    @include onSmallDesktop {
      font-size: 15px;
      line-height: 30px; /* 200% */
      letter-spacing: 0.6px;
    }

    @include onBigDesktop {
      font-size: 18px;
      letter-spacing: 1.2px;
    }
  }
}

.how-it-works {
  &__content {
    @include onPhone {
      padding-top: 84px;
    }

    @include onTablet {
      padding-top: 319px;
    }
  }
  &__title {
    text-align: center;
    width: 100%;

    @include onPhone {
      margin-bottom: 31px;
    }

    @include onTablet {
      margin-bottom: 88px;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
  }
  &__img {
    width: 100%; /* Adjust according to your layout */
    max-width: 799px; /* Optional: limits size */
    z-index: 1;

    @include onPhone {
      margin-bottom: 58px;
    }

    @include onTablet {
      margin-bottom: 0px;
    }
  }
  &__steps {
    display: flex;
    flex-direction: column;
  }
  &__step {
    display: flex;
    margin-bottom: 20px; /* Spacing between steps */
    align-items: flex-start;
  }
  &__number {
    align-self: flex-start;
    color: var(--Yellow, #ffe500);
    font-family: "Fira Code";
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 16px;

    @include onPhone {
      font-size: 48px;
      letter-spacing: 2.88px;
    }

    @include onTablet {
      font-size: 72px;
      letter-spacing: 4.32px;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
  }
  &__info_title {
    color: var(--Blue, #07cbf5); /* Blue color, with a fallback */
    font-family: "Fira Code", monospace; /* Specific font-family */
    font-style: normal; /* Font style */
    font-weight: 700; /* Bold font weight */
    line-height: normal; /* Normal line height */

    @include onPhone {
      font-size: 17px;
      letter-spacing: 1.02px;
    }

    @include onTablet {
      font-size: 24px; /* Set the font size */
      letter-spacing: 1.44px; /* Spacing between letters */
    }
  }
  &__info_desc {
    font-family: "Fira Code", monospace; /* Consistent font-family with the title */
    font-style: normal; /* Font style */
    line-height: normal; /* Normal line height */
    color: #ffffff; /* White color for readability */
    margin-top: auto;
    font-weight: 500;

    @include onPhone {
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.6px;
    }

    @include onTablet {
      font-size: 18px; /* Smaller font size than the title */
      line-height: 30px;
      letter-spacing: 1.08px; /* Spacing between letters */
    }
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: auto;
    margin-right: auto;

    background: url("../../images/button.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border: none;

    font-family: $fira-code;
    color: #000;
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.34px;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    z-index: 10;

    @include onPhone {
      width: 296px;
      height: 60px;
      margin-top: 20px;
    }

    @include onTablet {
      width: 216px;
      height: 74px;
      margin-top: 44px;
    }

    &:hover {
      color: $white-text-color;
      background: url("../../images/button_hover.svg");
      background-size: 100% 100%;
    }
  }
}

@media (min-width: 768px) {
  /* Big Tablet */
  .how-it-works__block {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  /* Desktop */
  .how-it-works__block {
    justify-content: space-between;
    align-items: start;
  }
}

.numbers {
  &__content {
    @include onPhone {
      padding-top: 120px;
    }

    @include onTablet {
      padding-top: 234px;
    }
  }
  &__title {
    text-align: center;
    width: 100%;
    margin-bottom: 42px;
  }
  &__block {
    display: flex;
    flex-direction: column; /* Change to column for smaller screens */
    justify-content: space-between;
    align-items: center;
    padding: 60px 40px; /* Reduce padding for smaller screens */

    @include onPhone {
      /* Tablet and above */
      background-image: url("../../images/numbers_bg_img_phone.svg");
      background-size: cover;
      background-repeat: no-repeat;
    }

    @include onTablet {
      /* Tablet and above */
      flex-direction: row; /* Row layout for larger screens */
      padding: 118px 90px;
      background-image: url("../../images/numbers_bg_img.svg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;
    padding: 16px;
    width: 100%;
  }
  .vertical-line {
    display: none; /* Hide lines on smaller screens */

    @include onTablet {
      /* Tablet and above */
      display: block; /* Show lines on larger screens */
      stroke-width: 1px;
      stroke: #fff;
      opacity: 0.3;
    }
  }

  .horizontal-line {
    display: block; /* Show lines on larger screens */
    stroke-width: 1px;
    stroke: #fff;
    margin-top: 32.88px;
    margin-bottom: 32.88px;

    @include onTablet {
      /* Tablet and above */
      display: none; /* Hide lines on smaller screens */
    }
  }

  .icon {
    flex-shrink: 0;
    fill: #ffe500; /* Assuming SVG icons are used */

    @include onPhone {
      width: 24px;
      height: 24px;
    }

    @include onTablet {
      width: 32px;
      height: 32px;
    }
  }
  .title {
    color: var(--Blue, #07cbf5);
    font-family: "Fira Code";
    font-weight: 600;
    line-height: normal;

    margin-top: 10px;

    @include onPhone {
      font-size: 28px; /* Smaller font size for smaller screens */

      letter-spacing: 1.68px; /* Adjust letter-spacing for smaller screens */
    }

    @include onTablet {
      /* Tablet and above */
      font-size: 40px;
      letter-spacing: 2.4px;
    }
  }
  .description {
    color: #fff;
    font-family: "Fira Code";
    /* Smaller font size for smaller screens */
    font-weight: 500;
    margin-top: 10px;

    @include onPhone {
      /* Tablet and above */
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 0.6px; /* Adjust letter-spacing for smaller screens */
    }
    @include onTablet {
      /* Tablet and above */
      font-size: 20px;
      line-height: 30px; /* 150% */
      letter-spacing: 1.2px;
    }
  }
}

.pricing {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include onTablet {
    flex-direction: column;
    align-items: center;
  }

  @include onSmallDesktop {
    flex-direction: row;
    align-items: flex-start;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: auto;
    margin-right: auto;

    background: url("../../images/button.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border: none;

    font-family: $fira-code;
    color: #000;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    z-index: 10;

    @include onPhone {
      font-size: 17px;
      letter-spacing: 0.34px;
      height: 60px;
      width: 280px;
      margin-top: 24px;
    }

    @include onTablet {
      font-size: 22px;
      letter-spacing: 0.44px;
      width: 100%;
      max-width: 386px;
      height: 74px;
      width: 386px;
      margin-top: 44px;
    }

    &:hover {
      color: $white-text-color;
      background: url("../../images/button_hover.svg");
      background-size: 100% 100%;
    }
  }
}

.column_plan {
  flex: 1 1 300px;

  background-image: url("../../images/select_plan_border_not_selected.svg");
  background-size: 100% 100%; /* Ensure background covers the entire element */
  min-height: 100%;

  @include onPhone {
    min-height: 736px; // Adjust this value as needed
    max-width: 480px;
    padding-top: 48px;
  }

  @include onTablet {
    width: 100%;
    min-height: 835px; // Adjust this value as needed
    max-width: 490px;
    padding-top: 0px;
  }
}

.column_plan.selected {
  @include onTablet {
    background-image: url("../../images/select_plan_border_selected.svg");
  }
}

.section-1 {
  color: var(--Yellow, #ffe500);
  text-align: center;
  font-family: $sugo-pro-display;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;

  @include onPhone {
    font-size: 40px;
    letter-spacing: 2.4px;
    margin-top: 45px;
  }

  @include onTablet {
    font-size: 52px;
    letter-spacing: 3.12px;
    margin-top: 61.85px;
  }
}

.section-2 {
  color: var(--Blue, #07cbf5);
  text-align: center;
  font-family: $fira-code;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2.88px;
  margin-top: 42px;

  @include onPhone {
    margin-top: 26px;
  }

  @include onTablet {
    margin-top: 42px;

    &__column-2 {
      margin-top: 14px;
    }
  }
}

.section-2 .row-1 {
  color: var(--Blue, #07cbf5);
  opacity: 0.4;
  text-decoration: line-through;
  text-decoration-color: var(--Yellow, #ffe500);

  @include onPhone {
    font-size: 20px;
    letter-spacing: 1.2px;
  }

  @include onTablet {
    font-size: 24px;
    letter-spacing: 1.44px;
  }
}

.section-2 .row-2 {
  color: var(--Blue, #07cbf5);

  @include onPhone {
    font-size: 36px;
    letter-spacing: 2.16px;
  }

  @include onTablet {
    font-size: 48px;
    letter-spacing: 2.88px;
  }
}

.section-2 .row-3 {
  color: var(--Blue, #07cbf5);

  @include onPhone {
    font-size: 17px;
    line-height: 32px;
    letter-spacing: 0.68px;
  }

  @include onTablet {
    font-size: 19px;
    line-height: 36px;
    letter-spacing: 1.14px;
  }
}

.section-3 {
  color: #fff;
  text-align: center;
  font-family: $fira-code;
  font-style: normal;
  font-weight: 500;
  margin-top: 25px;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;

  @include onPhone {
    font-size: 17px;
    line-height: 26px; /* 150% */
    letter-spacing: 0.68px;
    margin-left: 30px;
    margin-right: 30px;
  }

  @include onTablet {
    font-size: 20px;
    line-height: 30px; /* 150% */
    letter-spacing: 1.2px;
    margin-left: 57px;
    margin-right: 57px;
  }
}

.section-4 ul {
  list-style: none;
  padding: 0;

  @include onPhone {
    margin-top: 24px;
  }

  @include onTablet {
    margin-top: 35.35px;
  }
}

.section-4 ul li {
  color: var(--Yellow, #ffe500);
  font-family: $fira-code;
  font-style: normal;
  font-weight: 500;
  padding-left: 50px;

  @include onPhone {
    font-size: 17px;
    line-height: 30px; /* 150% */
    letter-spacing: 1.02px;
    margin-bottom: 12px;
    background: url("../../images/bullet.svg") no-repeat left center;
    background-size: 30px 30px;
    margin-left: 33px;
    margin-right: 16px;
  }

  @include onTablet {
    font-size: 20px;
    line-height: 30px; /* 150% */
    letter-spacing: 1.2px;
    margin-bottom: 24px;
    background: url("../../images/bullet.svg") no-repeat left center;
    background-size: 35px 35px;
    margin-left: 67px;
    margin-right: 20px;
  }
}

.home-clients {
  &__content {
    padding-top: 191px;
  }

  &__block {
    display: flex;
    width: 100%;
    height: 100%;
    margin-bottom: 3rem;

    position: relative;
    column-gap: 20px;

    transition: translate;
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;

    @include onPhone {
      flex-direction: column; // Stack columns vertically on mobile
      column-gap: 0; // Remove column gap on mobile
    }

    @include onTablet {
      flex-direction: unset;
      column-gap: 0;
    }
  }

  &__title {
    text-align: center;
    width: 100%;
    margin-bottom: 60.35px;
  }

  &__column {
    flex: 1 0 100%;
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    margin-bottom: 26px;

    @include onPhone {
      &:first-child {
        padding-right: 0px; // Adjust the value as needed
      }
    }

    @include onTablet {
      flex: content;
      row-gap: 24px;

      margin-bottom: 55px;

      &:first-child {
        padding-right: 22px; // Adjust the value as needed
      }
    }

    @include onSmallDesktop {
      margin-bottom: 50px;
    }
  }

  &__card {
    box-sizing: border-box;
    // max-width: 370px;
    width: 100%;
    height: max-content;
    background: url(../../images/our_page_mobile_bg_img.png);
    // background-size: contain;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    max-width: 746px;

    @include onPhone {
      padding: 39px 18px;
    }

    @include onTablet {
      padding: 39px 60px;
    }
  }

  &__top {
    // padding-top: 10px;
    display: flex;
    column-gap: 0.35rem;
    position: relative;
    margin-bottom: 20px;

    @include onSmallDesktop {
      margin-bottom: 16px;
    }

    @include onBigDesktop {
      margin-bottom: 24px;
    }
  }
  .home-clients__salary {
    color: $main-yellow-text-color;
    font-family: "Fira Code";
    
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 125% */
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 20px; /* Adjust if needed */

    @include onPhone {
      font-size: 17px;
      letter-spacing: 1.02px;
    }

    @include onTablet {
      font-size: 24px;
      letter-spacing: 1.44px;
    }
  }

  &__review {
    margin: 0;
    margin-bottom: 1.5rem;

    color: $white-text-color;
    font-family: $fira-code;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.6px;

    @include onPhone {
      line-height: 22px; /* 160% */
    }

    @include onTablet {
      line-height: 24px; /* 160% */
    }

    @include onBigDesktop {
      font-size: 18px;
      line-height: 27px; /* 150% */
      letter-spacing: 1.2px;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
  }

  &__client-img {
    width: 48px;
    height: 48px;
    // width: 100%;

    margin-right: 20px;

    @include onSmallDesktop {
      width: 52px;
      height: 52px;
    }

    @include onBigDesktop {
      width: 68px;
      height: 68px;
    }
  }

  &__name {
    color: $blue-text-color;
    font-family: $fira-code;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 166.667% */
    letter-spacing: 1.08px;
    text-transform: uppercase;

    @include onBigDesktop {
      font-size: 24px;
      letter-spacing: 1.44px;
    }
  }

  &__star {
    @include onPhone {
      width: 137.549px;
      height: 22px;
    }

    @include onTablet {
      width: 192px;
      height: 32px;
    }
  }

  &__position {
    color: $white-text-color;
    font-family: $fira-code;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 230.769% */
    letter-spacing: 0.52px;

    @include onSmallDesktop {
      font-size: 16px;
      letter-spacing: 0.64px;
    }

    @include onBigDesktop {
      font-size: 22px;
      letter-spacing: 1.32px;
    }
  }

  &__review-bottom {
    display: flex;
    flex-direction: row;
    row-gap: 1rem;

    @include onTablet {
      flex-direction: row;
      row-gap: 1rem;
    }

    @include onDesktop {
      row-gap: 3rem;
    }
  }

  &__review-bottom-info {
    display: flex;
    flex-direction: column; /* Stack name and position vertically */
    @include onTablet {
      row-gap: 0.5rem;
    }
  }

  &__name-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__name {
    color: $blue-text-color;
    font-family: $fira-code;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 166.667% */
    letter-spacing: 1.08px;
    text-transform: uppercase;

    @include onBigDesktop {
      font-size: 24px;
      letter-spacing: 1.44px;
    }
    margin-right: 10px; /* Adds 10px space between name and LinkedIn icon */
  }

  &__linkedin-link {
    display: inline-block;
  }

  &__linkedin-icon {
    flex-shrink: 0;
    @include onPhone {
      width: 18px;
      height: 18px;
    }
  
    @include onTablet {
      width: 24px;
      height: 24px;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    row-gap: 24px;

    @include onTablet {
      flex-direction: row;
      justify-content: flex-end;
      margin-left: 35px;
    }
  }

  &__arrow {
    display: flex;
    column-gap: 2.3rem;
  }

  &__arrow-img {
    height: 45px;
    cursor: pointer;

    @include onSmallDesktop {
      height: 62px;
    }
  }

  &__button {
    // padding: 1.35rem 5rem;
    width: 215px;
    height: 60px;

    background: url("../../images/button.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    border: none;

    font-family: $fira-code;
    color: #000;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.3px;

    cursor: pointer;

    &:hover {
      color: $white-text-color;

      background: url("../../images/button_hover.svg");
      background-size: cover;
      background-position: center;
    }

    @include onBigTablet {
      width: 246px;
      height: 60px;

      font-size: 15px;
      letter-spacing: 0.3px;
    }

    @include onSmallDesktop {
      width: 302px;
      height: 74px;

      font-size: 18px;
      letter-spacing: 0.36px;
    }

    @include onBigDesktop {
      width: 365px;
      height: 82px;

      font-size: 22px;
      letter-spacing: 0.44px;
    }
  }
}

.separator {
  width: 100%;
  position: relative;
  left: 50%;
  right: 50%;

  margin-left: -50vw;
  margin-right: -50vw;
  background: url("../../images/separator.svg") center no-repeat;
  background-size: cover;
  height: 160px;
  z-index: 0;

  &__first {
    margin-top: -160px;
  }
  &__second {
    margin-top: 155px;
  }

  @include onPhone {
    display: none;
  }

  @include onTablet {
    display: inline-block;
  }
}

.faq-cards {
  display: flex;
  flex-direction: column;
}

.faq-row-container {
  margin-bottom: 20px;
}

.faq-row {
  display: flex;
  align-items: center;

  cursor: pointer;
  justify-content: space-between;
  background: url("../../images/faq_question_border.svg") no-repeat;
  background-size: cover;

  @include onPhone {
    height: 84px;
    background-size: cover;
  }

  @include onTablet {
    height: 120.75px;
    background-size: 100% 100%;
  }
}

.faq-row-text {
  color: var(--Blue, #07cbf5);
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 500;
  flex-grow: 1; /* Ensures the text takes up available space */

  &--yellow {
    color: $main-yellow-text-color;
  }

  @include onPhone {
    font-size: 17px;
    line-height: 26px; /* 107.143% */
    letter-spacing: 0.68px;
    margin-left: 16px;
  }

  @include onTablet {
    font-size: 28px;
    line-height: 30px; /* 107.143% */
    letter-spacing: 1.68px;
    margin-left: 64px;
  }
}

.faq-arrow {
  margin-left: auto;
  margin-right: 48px;

  @include onPhone {
    width: 25.594px;
    height: 53.608px;
    margin-right: 15.92px;
  }

  @include onTablet {
    width: unset;
    height: unset;
    margin-right: 48px;
  }
}

.faq-card {
  margin-top: 16.25px;

  width: 100%;

  @include onPhone {
    background: url("../../images/faq_answer_border_mobile.svg") no-repeat;
    background-size: cover;
    padding-top: 16px;
    padding-bottom: 30px;
  }

  @include onTablet {
    background: url("../../images/faq_answer_border.svg") no-repeat;
    background-size: cover;
    padding-top: 37.5px;
    padding-bottom: 37.5px;
  }
}

.faq-card-text {
  color: var(--White, #fff);
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 500;

  @include onPhone {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.6px;

    margin-left: 16px;
    margin-right: 16px;
  }

  @include onTablet {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 1.44px;
    margin-left: 64px;
    margin-right: 64px;
  }
}
