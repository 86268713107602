@import "../../../styles/utils/vars";
@import "../../../styles/utils/mixins";

.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  font-family: $fira-code;

  &__line-wrapper {
    width: 100%;
  }

  &__line {
    width: 100%;

    @include onPhone {
      margin-top: 40px;
    }

    @include onTablet {
      margin-top: 140px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__logo {
    display: flex;
    position: relative;

    &-image {
      width: 100%;
      height: auto; /* Maintain aspect ratio */
      object-fit: contain; /* Ensure the image fits within the container */
    }

    @include onPhone {
      display: none;
    }

    @include onTablet {
      display: inline-block;
      width: 201px;
      align-items: center;
    }
  }

  &__top {
    margin-top: 48px;
    height: 229px;
    width: 100%;
    background-size: cover;
    background-repeat: repeat;
    background-position: top;
  }

  &__bottom-wrapper {
    position: absolute;
    left: 50%;
    width: 100vw;
    transform: translateX(-50%);
  }

  &__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    background: rgba(255, 255, 255, 0.15);
  }

  &__block {
    display: flex;
    justify-content: space-between;

    @include onPhone {
      flex-direction: column;
    }

    @include onTablet {
      flex-direction: row;
    }
  }

  &__list-block {
    justify-self: flex-end;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 5px;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 1.3rem;
    line-height: 2rem;

    &--logo {
      padding: 0;
      margin-top: -2px;
      row-gap: 0;
      list-style: none;
    }

    &--menu {
      @include onPhone {
        order: 3;
      }

      @include onTablet {
        order: 1;
      }
    }

    &--menu-2 {
      @include onPhone {
        order: 4;
        margin-bottom: 20px;
      }

      @include onTablet {
        order: 2;
      }
    }

    &--contacts {
      @include onPhone {
        order: 1;
        margin-bottom: 20px;
      }

      @include onTablet {
        order: 2;
      }
    }

    &--slogan {
      color: var(--White, #fff);
      font-family: "Fira Code";
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 173.333% */
      letter-spacing: 0.9px;
      opacity: 0.7;

      @include onPhone {
        order: 2;
        margin-bottom: 20px;
      }

      @include onTablet {
        display: none;
      }
    }
  }

  &__img {
    width: 142px;
  }

  &__img-block {
    display: block;
    margin: 0;
    padding: 0;
    line-height: 20px;
  }

  &__item {
    margin-bottom: 10px;
  }

  &__link {
    text-decoration: none;
    color: $white-text-color;
    font-family: $fira-code;
    font-style: normal;
    font-weight: 500;

    @include onPhone {
      font-size: 17px;
      line-height: 30px; /* 176.471% */
      letter-spacing: 0.34px;
    }

    @include onTablet {
      font-size: 20px;
      line-height: 30px; /* 150% */
      letter-spacing: 0.4px;
    }

    &:hover {
      color: $blue-text-color;
    }
  }

  &__contacts-title {
    color: #ffe500;
    font-family: $fira-code;
    font-style: normal;
    font-weight: 500;

    @include onPhone {
      font-size: 17px;
      line-height: 26px; /* 152.941% */
      letter-spacing: 0.34px;
      margin-bottom: 16px;
    }

    @include onTablet {
      font-size: 20px;
      line-height: 30px; /* 150% */
      letter-spacing: 0.4px;
      margin-bottom: 20px;
    }
  }

  &__contact-info {
    display: flex;
    align-items: center;
    color: #fff;
    font-family: $fira-code;
    font-style: normal;
    font-weight: 600;

    @include onPhone {
      font-size: 15px;
      line-height: 30px; /* 200% */
      letter-spacing: 0.3px;
      margin-bottom: 8px;
    }

    @include onTablet {
      font-size: 20px;
      line-height: 30px; /* 150% */
      letter-spacing: 0.4px;
      margin-bottom: 20px;
    }
  }

  &__contact-icon {
    margin-right: 8px; /* Adjust this value as needed */

    @include onPhone {
      width: 26px;
      height: 26px;
    }

    @include onTablet {
      width: 32px;
      height: 32px;
    }
  }

  &__bottom-text {
    margin: 0;
    text-align: center;
    color: var(--White, #fff);
    font-family: "Fira Code";
    font-style: normal;
    font-weight: 500;
    margin-top: 14px;
    margin-bottom: 14px;

    @include onPhone {
      font-size: 13px;
      line-height: 26px; /* 200% */
      letter-spacing: 0.78px;
    }

    @include onTablet {
      font-size: 16px;
      line-height: 42px; /* 262.5% */
      letter-spacing: 0.96px;
    }
  }
}

.break-on-phone {
  @include onPhone {
    display: block;
  }

  @include onTablet {
    display: inline-block;
  }
}
