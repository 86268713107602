@import "../../styles/utils/vars";
@import "../../styles/utils/mixins";

.demo-request {
  z-index: 1;

  &__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include onTablet {
      flex-direction: row;
      justify-content: space-between;
      column-gap: 1rem;
    }
  }

  &__text-block {
    position: relative;
    z-index: 2;

    @include onPhone {
      max-width: 480px;
      margin-top: 66px;
    }

    @include onTablet {
      max-width: 753px;
      margin-top: 110px;
    }
  }

  &__title-container {
    display: flex;
    align-items: center;

    @include onPhone {
      flex-direction: column;
    }

    @include onTablet {
      flex-direction: row;
    }
  }

  &__page-title {
    &--position {
      margin-bottom: 70px;

      @include onBigTablet {
        margin-bottom: 100px;
      }
    }
  }

  &__illustration {
    @include onPhone {
      display: none;
    }

    @include onTablet {
      display: inline-flex;
      width: 737px;
      height: 888px;
      margin-top: 0;
      text-align: start;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      z-index: 1;
    }
  }

  &__back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #fff;
    font-family: "Fira Code";
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    background: none; /* Ensure there's no background if it's a button */
    border: none; /* Remove any default border */
    cursor: pointer; /* Show pointer cursor on hover */

    @include onPhone {
      font-size: 17px;
      letter-spacing: 0.34px;
      margin-top: 20px; /* Adjust spacing as needed */
    }

    @include onTablet {
      font-size: 22px;
      letter-spacing: 0.44px;
      margin-top: 28px; /* Adjust spacing as needed */
    }

    &:hover {
      color: $blue-text-color;
    }
  }
}

.checkmark-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;

  .success-message {
    margin-top: 16px;
    color: #fff;
    font-weight: bold;

    @include onPhone {
      font-size: 20px;
    }

    @include onTablet {
      font-size: 24px;
    }
  }

  .contact-message {
    margin-top: 8px;
    color: #fff;

    @include onPhone {
      font-size: 16px;
    }

    @include onTablet {
      font-size: 18px;
    }
  }
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}
