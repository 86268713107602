@import "utils/vars";
@import "utils/_mixins";
@import "fonts";

body {
  margin: 0;
  font-family: "Fira Code", "Sugo Pro Display", "Sugo Pro Classic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: "Fira Code", "Sugo Pro Display", "Sugo Pro Classic", monospace;
}

.page {
  position: relative;
}

.container {
  box-sizing: border-box;

  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  margin-right: auto;
  padding-inline: 24px;

  @include onPhone {
    padding-inline: 16px;
  }

  @include onBigBigDesktop {
    max-width: 1500px;
  }

  &__reviews {
    @include onPhone {
      padding-inline: 0px;
    }
  }
}

.main {
  overflow: hidden;

  &__content {
    padding-top: 231px;

    @include onPhone {
      padding-top: 0;
    }

    @include onSmallDesktop {
      padding-top: 134px;
    }
  }
}
