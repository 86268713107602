@import "../../../styles/utils/vars";
@import "../../../styles/utils/mixins";

.logo {
  width: 10rem;
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;

  transition: top 0.4s;

  background-color: #000;

  @include onTablet {
    height: unset;
  }

  &--close {
    @include onPhone {
      height: 82px;
    }

    top: -100%;

    transition: top 0.7s;
  }

  &--none-shadow {
    position: relative;
    box-shadow: none;
  }

  &__portfolio {
    @include onTablet {
      box-shadow: none;
    }
  }

  &__content {
    display: flex;
    align-items: center;

    @include onPhone {
      padding-top: 16px;
    }

    @include onTablet {
      padding-top: 39px;
    }
  }

  &__menu-img {
    cursor: pointer;
  }

  &__left-block {
    display: flex;
    align-items: center;
  }

  &__logo {
    display: flex;
    position: relative;

    &-image {
      width: 100%;
      height: auto; /* Maintain aspect ratio */
      object-fit: contain; /* Ensure the image fits within the container */
    }

    @include onPhone {
      width: 132.36px;
      align-items: start;
    }

    @include onTablet {
      width: 201px;
      align-items: center;
    }
  }

  &__menu {
    &-right {
      display: block;

      @include onTablet {
        display: none;
      }
    }

    &-left {
      display: none;

      @include onTablet {
        display: block;
      }

      @include onDesktop {
        display: none;
      }
    }
  }

  &__central-block {
    align-items: center;
    margin-left: 126px;

    @include onPhone {
      display: none;
    }
    @include onTablet {
      display: flex;
    }
  }

  &__right-block {
    display: flex;
    height: 100%;
    align-items: center;
    align-self: center;
    margin-left: auto;

    @include onPhone {
      margin-right: 0px;
    }

    @include onTablet {
      margin-right: 72px;
    }
  }

  &__nav-list {
    display: none;

    @include onDesktop {
      display: flex;
      column-gap: 40px;
    }
  }

  &__nav-link {
    color: $white-text-color;
    font-family: Fira Code;
    font-size: 1.1rem;
    text-decoration: none;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 150% */
    letter-spacing: 0.4px;

    &--about-us {
      &:hover {
        color: $blue-text-color;
      }
    }

    &--portfolio {
      &:hover {
        color: $blue-text-color;
      }
    }

    &--reviews {
      &:hover {
        color: $blue-text-color;
      }
    }
  }

  &__contact-info {
    display: flex;
    align-items: center;
  }

  &__phone-icon {
    flex-shrink: 0;

    @include onPhone {
      width: 28px;
      height: 28px;
      margin-right: 12px;
    }

    @include onTablet {
      width: 32px;
      height: 32px;
      margin-right: 0px;
    }
  }

  &__telegram-icon {
    flex-shrink: 0;

    @include onPhone {
      width: 28px;
      height: 28px;
      margin-right: 20px;
    }

    @include onTablet {
      display: none;
    }
  }

  &__phone-text {
    margin-left: 8px;
    color: #fff;
    font-family: "Fira Code";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 150% */
    letter-spacing: 0.4px;

    @include onPhone {
      display: none;
    }

    @include onTablet {
      display: inline-block;
    }
  }
  &__language-dropdown {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin-left: 40px; /* Push to the right side */

    @include onPhone {
      display: none;
    }

    @include onTablet {
      display: inline-block;
    }
  }

  &__language-text {
    margin-right: 8px; /* Space between text and arrow */
    color: #fff;
  }

  &__dropdown-arrow {
    width: 12px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
  }

  &__dropdown-content {
    top: 100%;
    left: 0;
    position: absolute;
    background-color: #000;
    padding: 14px 0;
    z-index: 1;
  }

  &__dropdown-content a {
    color: #fff; /* Ensure text color is white */
    text-decoration: none;
    display: block;
    padding: 8px 16px;
  }

  &__dropdown-content.active {
    display: block;
  }

  &__dropdown-content a:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  &__language-dropdown.active &__dropdown-content {
    display: block;
  }

  &__button {
    display: none;

    @include onTablet {
      display: block;
      padding: 0.6rem 1.5rem;

      border: 2px solid $sub-yellow-text-color;
      background-color: transparent;

      font-family: "Fira Code";
      color: #f7e439;
      text-align: center;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        background-color: $sub-yellow-text-color;
        color: $dark-text-color;
      }
    }
  }
}

.header-menu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;

  transition: transform;
  transition-duration: 300ms;
  transform: translateX(-100%);

  overflow-y: auto;

  @include onTablet {
    width: 420px;
  }

  @include onDesktop {
    display: none;
  }

  &--open {
    transform: translateX(0);

    transition: transform 0.3s;

    @include onTablet {
      box-shadow: 50px 0px 80px 50px black;
    }
  }

  &__content {
    block-size: border-box;
    padding: 24px;
    padding-top: 0;
    justify-content: space-between;
    height: 100%;

    display: flex;
    flex-direction: column;

    @include onTablet {
      padding: 24px 0;
    }
  }

  &__text {
    margin-bottom: 20px;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    align-items: flex-start;

    // margin-bottom: 150px;
  }

  &__link {
    color: $white-text-color;
    font-family: "Sugo Pro Classic";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;
    text-decoration: none;
  }

  &__text-block {
    display: flex;
    align-items: center;

    column-gap: 16px;

    &__int {
      color: $main-yellow-text-color;
      font-size: 0.95rem;
      font-style: normal;
      font-weight: 500;
      line-height: 36px; /* 240% */
      letter-spacing: 0.9px;

      text-transform: uppercase;
    }
  }

  &__language-options {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 24px; /* Adjust as needed */
  }

  &__language {
    color: #fff;
    font-family: "Fira Code";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 150% */
    letter-spacing: 0.4px;
    margin-right: 24px; /* Space between languages */
    cursor: pointer;

    &--selected {
      color: $main-yellow-text-color;
    }
  }

  &__contact-text {
    display: block;
    color: #ececec;

    font-size: 1.05rem;
    font-style: normal;
    font-weight: 450;
    line-height: 36px; /* 211.765% */
    letter-spacing: 1.02px;
    text-decoration: none;
  }

  &__country {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 100% */
    letter-spacing: 1.32px;
    text-transform: uppercase;

    &__us {
      display: block;
      color: #e2209e;
    }

    &__ua {
      display: block;
      color: #07cbf5;
    }
  }

  &__icon {
    width: 28px;
    height: 28px;
  }

  &__button {
    max-width: 400px;
    width: 100%;
    height: 55px;

    border: 2px solid #f7e439;
    background-color: transparent;

    color: #f7e439;

    text-align: center;
    font-family: Fira Code;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    text-transform: uppercase;
  }
}
