@import "../../styles/utils/vars";
@import "../../styles/utils/mixins";

.pre-payment {
  z-index: 1;

  &__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include onTablet {
      flex-direction: row;
      justify-content: space-between;
      column-gap: 1rem;
    }
  }

  &__text-block {
    position: relative;
    z-index: 2;

    @include onPhone {
      max-width: 480px;
      margin-top: 66px;
    }

    @include onTablet {
      max-width: 753px;
      margin-top: 110px;
    }
  }

  &__title-container {
    display: flex;
    align-items: center;

    @include onPhone {
      flex-direction: column;
    }

    @include onTablet {
      flex-direction: row;
    }
  }

  &__page-title {
    &--position {
      margin-bottom: 70px;

      @include onBigTablet {
        margin-bottom: 100px;
      }
    }
  }

  &__price {
    color: var(--Blue, #07cbf5);
    text-align: center;
    font-family: "Fira Code";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2.88px;

    @include onPhone {
      margin-left: 0px;
    }

    @include onTablet {
      margin-left: 20px;
    }
  }

  &__price-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-left: 20px; /* Adjust as needed */
  }

  &__price-row {
    color: var(--Blue, #07cbf5);
    text-align: center;
    font-family: "Fira Code";
    font-style: normal;
    font-weight: 600;
  }

  &__price-row--strike {
    font-size: 24px;
    line-height: normal;
    letter-spacing: 1.44px;
    position: relative;
    opacity: 0.4;
  }

  &__price-row--strike::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
    background-color: yellow;
    transform: translateY(-50%);
  }

  &__price-row:last-child {
    font-size: 19px;
    line-height: 36px;
    letter-spacing: 1.14px;
  }

  &__illustration {
    @include onPhone {
      display: none;
    }

    @include onTablet {
      display: inline-flex;
      width: 737px;
      height: 888px;
      margin-top: 0;
      text-align: start;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      z-index: 1;
    }
  }

  &__back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #fff;
    font-family: "Fira Code";
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    background: none; /* Ensure there's no background if it's a button */
    border: none; /* Remove any default border */
    cursor: pointer; /* Show pointer cursor on hover */

    @include onPhone {
      font-size: 17px;
      letter-spacing: 0.34px;
      margin-top: 20px; /* Adjust spacing as needed */
    }

    @include onTablet {
      font-size: 22px;
      letter-spacing: 0.44px;
      margin-top: 28px; /* Adjust spacing as needed */
    }

    &:hover {
      color: $blue-text-color;
    }
  }
}
