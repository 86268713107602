@import "../../styles/utils/vars";
@import "../../styles/utils/mixins";

.page-title-reviews {
  margin: 0;
  position: relative;
  display: inline-block;

  font-family: $sugo-pro-display;
  color: $white-text-color;
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px; /* 100% */
  letter-spacing: 3.12px;
  text-transform: uppercase;

  text-shadow:
    -1px -1px 0px #000000,
    2.5px 2.5px 0px #000000,
    3.5px 3.5px 0px #808080;

  @include onSmallDesktop {
    font-size: 70px;
    line-height: 70px; /* 100% */
    letter-spacing: 4.2px;
    text-shadow:
      -1px -1px 0px #000000,
      2.5px 2.5px 0px #000000,
      3.5px 3.5px 0px #808080;
  }

  @include onBigDesktop {
    font-size: 100px;
    line-height: 112px; /* 112% */
    letter-spacing: 6px;
    text-shadow:
      -1px -1px 0px #000000,
      3.5px 3.5px 0px #000000,
      5px 5px 0px #808080;
  }

  &--yellow {
    color: $main-yellow-text-color;
  }

  &--pink {
    color: $pink2-text-color;
  }
}

.reviews-top {
  &__text-block {
    max-width: 500px;
    margin-bottom: 20px;
    margin-top: 100px;
  }

  &__sub-text {
    margin: 0;

    color: $white-text-color;
    font-family: $fira-code;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 173.333% */
    letter-spacing: 0.6px;

    @include onBigDesktop {
      font-size: 20px;
      line-height: 30px; /* 150% */
      letter-spacing: 1.2px;
    }
  }
}

.reviews-main {
  &__content {
    display: grid;
    grid-template-columns: minmax(320px, 500px);
    row-gap: 20px;
    justify-content: center;
    align-items: center;

    @include onBigTablet {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 10px;
      row-gap: 32px;
    }

    @include onBigDesktop {
      column-gap: 20px;
      row-gap: 48px;
    }
  }

  &__salary {
    color: $main-yellow-text-color;
    font-family: "Fira Code";
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 125% */
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 20px; /* Adjust if needed */

    @include onPhone {
      font-size: 17px;
      letter-spacing: 1.02px;
    }

    @include onTablet {
      font-size: 24px;
      letter-spacing: 1.44px;
    }
  }

  &__page-title {
    &--position {
      margin-bottom: 26px;

      @include onSmallDesktop {
        margin-bottom: 20px;
      }

      @include onBigDesktop {
        margin-bottom: 24px;
      }
    }
  }

  &__card {
    width: 100%;
    height: max-content;
    box-sizing: border-box;
    background: url(../../images/reviews_mobile_bg.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 30px 25px;
    padding-left: 35px;

    @include onBigTablet {
      background: url(../../images/reviews_bg.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    @include onDesktop {
      padding: 32px 80px;
    }

    &--doshed-bg {
      height: max-content;
      background-image: url(../../images/reviews_doshed_mobile_bg.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat;

      @include onBigTablet {
        background: url(../../images/reviews_doshed_bg.svg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }

    &--position-1 {
      @include onDesktop {
        margin-right: 80px;
      }
    }

    &--position-2 {
      @include onDesktop {
        margin-left: 50px;
      }
    }

    &--change-bg-1 {
      background: url(../../images/reviews_mobile_bg.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat;

      @include onBigTablet {
        background: url(../../images/reviews_doshed_bg.svg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }

    &--change-bg-2 {
      background-image: url(../../images/reviews_doshed_mobile_bg.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat;

      @include onBigTablet {
        background: url(../../images/reviews_bg.svg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }

  &__top {
    // padding-top: 10px;
    display: flex;
    column-gap: 0.35rem;
    position: relative;
    margin-bottom: 20px;

    @include onSmallDesktop {
      margin-bottom: 16px;
    }

    @include onBigDesktop {
      margin-bottom: 24px;
    }
  }

  &__star {
    width: 22px;

    @include onSmallDesktop {
      width: 26px;
    }

    @include onBigDesktop {
      width: 32px;
    }
  }

  &__review {
    margin: 0;
    margin-bottom: 24px;

    color: $white-text-color;
    font-family: $fira-code;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 184.615% */
    letter-spacing: 0.52px;

    @include onSmallDesktop {
      margin-bottom: 16px;
    }

    @include onBigDesktop {
      margin-bottom: 28px;

      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px; /* 150% */
      letter-spacing: 1.2px;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
  }

  &__client-img {
    width: 44px;
    height: 44px;

    @include onBigDesktop {
      width: 68px;
      height: 68px;
    }

    margin-right: 1.5rem;
  }

  &__name {
    color: $blue-text-color;
    font-family: $fira-code;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.02px;
    text-transform: uppercase;

    @include onBigDesktop {
      font-size: 24px;
      line-height: 30px; /* 125% */
      letter-spacing: 1.44px;
    }
  }

  &__position {
    color: $white-text-color;
    font-family: $fira-code;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;

    @include onSmallDesktop {
      font-size: 13px;
      letter-spacing: 0.78px;
    }

    @include onBigDesktop {
      font-size: 20px;
      line-height: 30px; /* 150% */
      letter-spacing: 1.2px;
    }
  }

  &__review-bottom {
    display: flex;
    flex-direction: row;
    row-gap: 1rem;

    @include onTablet {
      flex-direction: row;
      row-gap: 1rem;
    }

    @include onDesktop {
      row-gap: 3rem;
    }
  }

  &__review-bottom-info {
    // margin-top: 5px;
    display: flex;
    flex-direction: column;


    @include onSmallDesktop {
      margin-top: 0;
    }

    @include onBigDesktop {
      margin-top: 5px;
      row-gap: 8px;
    }
  }

  &__review-bottom-info-gap {
    // margin-top: 5px;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    @include onSmallDesktop {
      margin-top: 0;
    }

    @include onBigDesktop {
      margin-top: 5px;
      row-gap: 8px;
    }
  }
}
